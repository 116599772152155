<template>

    <div>
        <NuxtLayout>
            <template #header>
                <Header>
                    <template #header>
                        <span>404.</span>
                    </template>
                    <template #subheader>
                        <span class="text-white/50">not sure what you're looking for here.</span>
                    </template>
                </Header>
            </template>
            <template #content>
                <a href="/" class="text-white hover:underline">go back home</a>
            </template>
        </NuxtLayout>
    </div>


</template>
<script setup>

</script>

<style lang="scss" scoped>

</style>